<template>
  <div>
    <Title title="Flightscope Aviation" subtitle="Flight Training Programs" />
    <div class="grid-container">
      <ProgramCard title="RECREATIONAL PILOT CERTIFICATE" subtitle="The beginning of your journey" :imagePath="require('@/assets/images/www/cards/1-1400.jpg')" route="/recreational-pilot-certificate">
        <p>The Recreational Pilot Certificate (RPC) allows you to fly an RA-Aus registered aircraft 25nm from your point of departure.</p>
        <ul class="card-list" aria-label="Requirements:">
          <li>Minimum hours: 20</li>
          <li>15 hours dual &amp; 5 hours solo</li>
          <li>Medical standard of a drivers licence</li>
          <li>Minimum age: 15 years</li>
        </ul>
      </ProgramCard>

      <ProgramCard title="RECREATIONAL PILOT LICENCE" subtitle="The first CASA licence" :imagePath="require('@/assets/images/www/cards/2-1400.jpg')" route="/recreational-pilot-licence">
        <p>The Recreational Pilot Licence allows you to fly a light single engine aircraft as the Pilot in Command without supervision within 25nm of your departure point.</p>
        <ul class="card-list" aria-label="Requirements:">
          <li>Flight time as pilot: 25 hours</li>
          <li>Solo flight time: 5 hours</li>
          <li>Dual Instrument time: 2 hours</li>
          <li>English Language Assessment</li>
          <li>RPL theory exam </li>
          <li>Class 1 or 2 medical</li>
        </ul>
        <p><b>Note:</b> If you have a RPC you can convert across to a RPL by conducting a flight review.</p>
        <p>You can add navigation and controlled airspace endorsements to this licence.</p>
      </ProgramCard>

      <ProgramCard title="RA-AUS NAVIGATION ENDORSEMENT" subtitle="The freedom to navigate" :imagePath="require('@/assets/images/www/cards/3-1400.jpg')" route="/ra-aus-navigation-endorsement">
        <p>If you have a RPC and wish to navigate more than 25nm from your departure point.</p>
        <ul class="card-list" aria-label="Requirements:">
          <li>12 hours navigation experience including 2 hours solo navigation.</li>
          <li>Private hire a RA-Aus registered aircraft and fly anywhere in Australia.</li>
        </ul>
        <ul class="card-list" aria-label="Limitations:">
          <li>Private hire a RA-Aus registered aircraft and fly anywhere in Australia.</li>
        </ul>
      </ProgramCard>

      <ProgramCard title="RA-AUS PASSENGER CARRYING ENDORSEMENT" subtitle="A perfect fit" :imagePath="require('@/assets/images/www/cards/4-1400.jpg')" route="/ra-aus-passenger-carrying-endorsement">
        <p>This endorsement allows you to share your flying experience with your friends and family.</p>
        <p>With this you can take 1 passenger up with you when you fly in an RA-Aus registered aircraft.</p>
      </ProgramCard>

      <ProgramCard title="PRIVATE PILOT LICENCE" subtitle="Pilot-in-command or co-pilot for private operations" :imagePath="require('@/assets/images/www/cards/5-1400.jpg')" route="/private-pilot-licence">
        <p>The Private Pilot Licence allows you as the holder to fly an aircraft around Australia as pilot-in-command or co-pilot in private operations.</p>
        <ul class="card-list" aria-label="Requirements:">
          <li>Aeronautical experience: 40 hours</li>
          <li>Flight time as pilot: 35 hours</li>
          <li>Solo flight time: 10 hours</li>
          <li>Solo cross-country: 5 hours</li>
          <li>Dual Instrument time: 2 hours</li>
          <li>Dual instrument flight time: 1 hour</li>
        </ul>
        <p>We do our Private Pilot Licences in our German Aquila A210 with constant speed unit.</p>
      </ProgramCard>

      <ProgramCard title="COMMERCIAL PILOT LICENCE" subtitle="Fly for a living" :imagePath="require('@/assets/images/www/cards/6-1400.jpg')" route="/commercial-pilot-licence">
        <ul class="card-list" aria-label="Requirements:">
          <li>Minimum Age: 18 years old</li>
          <li>Aeronautical Experience: Minimum 200 hrs</li>
          <li>Pilot in Command Flight Time: Minimum 70-100 hrs</li>
          <li>Pilot in Command Cross-country: Minimum 20 hrs</li>
          <li>Dual instrument time: Minimum 10 hrs</li>
          <li>Dual instrument fight time: Minimum 5 hrs</li>
          <li>Medical: Class 1</li>
        </ul>
      </ProgramCard>

      <ProgramCard title="MULTI-ENGINE CLASS RATING" subtitle="Two is better than one" :imagePath="require('@/assets/images/www/cards/multi-engine-rating-1400.png')" route="/multi-engine-rating">
        <ul class="card-list" aria-label="Requirements:">
          <li>Private Pilot Licence or higher</li>
          <li>Competency-based</li>
        </ul>
      </ProgramCard>

      <ProgramCard title="RPC TO RPL CONVERSION" subtitle="Jump in" :imagePath="require('@/assets/images/www/cards/7-1400.jpg')" route="/rpc-to-rpl-conversion">
        <p>To convert to a RPL you need to complete the CASA application form and follow the instructions on the form to provide evidence of your pilot certificate, submit a recent photograph and provide appropriate proof of your identity. You also need to undertake a flight review before you can use your licence.</p>
      </ProgramCard>

      <ProgramCard title="UPRT - UPSET PREVENTION AND RECOVERY TRAINING" subtitle="Safety first" :imagePath="require('@/assets/images/www/cards/8-1400.jpg')" route="/uprt-upset-prevention-and-recovery-training">
        <p>Whether you're looking to improve your skills or simply gain more confidence, our UPRT offers everything you need and more. Contact us today with any questions or for more information.</p>
      </ProgramCard>

      <ProgramCard title="TAILWHEEL ENDORSEMENT" subtitle="Challenge yourself" :imagePath="require('@/assets/images/www/cards/9-1400.jpg')" route="/tailwheel-endorsement">
        <p>This fun and challenging endorsement allows you to fly tailwheel aircraft.</p>
      </ProgramCard>

      <ProgramCard title="FORMATION FLYING" subtitle="Fly with finesse" :imagePath="require('@/assets/images/www/cards/10-1400.jpg')" route="/formation-flying">
        <p>Challenge yourself and have fun while improving your flight control skills.</p>
      </ProgramCard>

      <ProgramCard class="last" title="RA-AUS FLIGHT INSTRUCTOR COURSE" subtitle="Teach what you love" :imagePath="require('@/assets/images/www/cards/11-1400.jpg')" route="/ra-aus-flight-instructor-course">
        <p> Our RA-Aus flight instructor course is conducted by our Chief Flying Instructor, Rod Flockhart, who says instructors are the teachers of good habits!</p>
        <ul class="card-list" aria-label="Requirements:">
          <li>100 hours PIC of RA-Aus aircraft</li>
          <li>Radio Operator</li>
          <li>Passenger Carrying</li>
          <li>Cross Country</li>
          <li>Human Factors</li>
          <li>Undertake a Principles of Methods of Instruction Course</li>
        </ul>
        <ul class="card-list" aria-label="The course requires:">
          <li>20 hours of dual in-flight instruction</li>
          <li>30 hours of ground theory instruction</li>
        </ul>
      </ProgramCard>

      <ProgramCard class="last" title="Grade 3 FLIGHT INSTRUCTOR COURSE" subtitle="Learning in a positive environment" :imagePath="require('@/assets/images/www/cards/ga-instructor-rating-1400.png')" route="/ga-flight-instructor-course">
        <p>Our Grade 3 flight instructor course introduces you to flight training in a General Aviation environment.</p>
        <ul class="card-list" aria-label="Requirements:">
          <li>200 hours aeronautical experience</li>
          <li>100 aeronautical experience as Pilot in Command</li>
          <li>40 hours of dual in-flight instruction</li>
          <li>Private or Commercial Pilot Licence</li>
          <li>Spinning Flight Activity Endorsement</li>
          <li>Complete an approved course of training in principles and methods of instruction (PMI)</li>
        </ul>
      </ProgramCard>

      <ProgramCard class="last" title="AEROBATIC ENDORSEMENT" subtitle="Serious fun" :imagePath="require('@/assets/images/www/cards/12-1400.jpg')" route="/aerobatic-endorsement">
        <p>Aerobatic training in the amazing Super Decathlon</p>
      </ProgramCard>
    </div>
  </div>
</template>

<script>
import ProgramCard from '@/components/ProgramCard.vue'
import Title from '@/components/Title.vue'

export default {
  components: {
    ProgramCard,
    Title
  }
}
</script>

<style lang="scss">
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.grid-container {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-auto-rows: 1fr;
  gap: 3vh;
  width: 90vw;
}

.card-list {
  list-style-type: square;
  margin-left: -2.5em;
  &:before {
    content: attr(aria-label);
    font-weight: bold;
  }
}
</style>
