<template>
  <router-link :to="route" class="card-container" :style="style">
    <div class="card">
      <h1>{{ title }}</h1>
      <h2>{{ subtitle }}</h2>
      <slot />
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['title', 'subtitle', 'imagePath', 'route'],
  computed: {
    style () {
      return `background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${this.imagePath}) no-repeat center; background-size: cover;`
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/_presets.scss';
@import '@/styles/_colors.scss';

.card-container {
  @extend .rounded;
  justify-content: center;
  align-items: center;
  position: relative;
  color: $secondary;
  cursor: pointer;
  box-shadow: lighten($primary, 5%) 0px 10px 10px;
  transition: all .3s ease-in-out;
  text-decoration: none;
  max-width: 90vw;

  &:hover {
    box-shadow: $primary 0px 19px 19px;
    transform: translate3d(0px, -5px, 0px);
    color: $secondary;
  }
}

.card {
  width: 95%;
  margin: auto;
}
</style>
